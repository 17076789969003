import { ChannelUtil, TerminalUtil } from "../utils/checkSession";
import { session_instance } from "./ApiClient";

import { getSessionStorage, setSessionStorage } from "../../src/utils/storage";
const accessMap = {
	BRANCH: "Branch",
	TERMINAL: "TPAID",
	CHANNEL: "User",
};

export default async function checkChannelBranchTpa() {
	let isEnabledExpired = true;
	const terminal = TerminalUtil.get();
	const sessionStorage = getSessionStorage("isEnabledTime");

	if (sessionStorage) {
		const time_now = new Date();
		const storage_time = new Date(sessionStorage);
		const expiration = new Date(storage_time.getTime() + 60000);
		if (time_now <= expiration) {
			isEnabledExpired = false;
		}
	}

	if (isEnabledExpired) {
		setSessionStorage("isEnabledTime", Date.now());
		const { data } = await session_instance.get(
			`/v3/channel/branch/terminal/${terminal.id}/is-enabled`
		);
		let channels = ChannelUtil.get();
		if (!channels.length) {
			ChannelUtil.set([data.data]);
		} else {
			if (
				!channels.find((item) => item.channel_id === data.data.channel_id)
			) {
				ChannelUtil.set([data.data]);
			}
		}
		if (data.data.disabled) {
			return accessMap[data.data.disabled];
		}
		return null;
	}
}
