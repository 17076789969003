import React, { useEffect, useRef, useState } from "react";
import {
	Grid,
	Divider,
	Dialog,
	Box,
	DialogActions,
	Button,
	Typography,
	DialogTitle,
	CircularProgress,
	IconButton,
} from "@material-ui/core";

import CloseIcon from "@material-ui/icons/Close";

import styles from "./ExpiredValidateModal.module.css";
import { useDispatch, useSelector } from "react-redux";
import { disableCommit } from "../../redux/modules/timeout";
import moment from "moment";

const ExpiredValidateModal = ({open, handleOnCloseModal}) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
	const timerActive = useRef(false);
	const timeLeft = useRef(15 * 60); // 15 minutes in seconds
    //const timeLeft = useRef(30); // Reset to 30 second for testing purposes
    const dispatch = useDispatch();
    const showValidateExpiryModal = useSelector((store) => store.timeout.showValidateExpiryModal);

	useEffect(() => {
		let timer;

		if (open) {
			timerActive.current = true;
			if (timerActive.current && timeLeft.current > 0) {
				timer = setInterval(() => {
					timeLeft.current -= 1;
                    const formattedSeconds = moment().startOf('day').seconds(timeLeft.current).format('mm:ss');
					console.log("Time Left:", formattedSeconds);
					if (timeLeft.current <= 0) {
						clearInterval(timer);
                        dispatch(disableCommit())
						setIsModalOpen(true);
					}
				}, 1000);
			}
		}

		return () => {
			clearInterval(timer);
		};
	}, [open]);

    useEffect(() => {
        if(showValidateExpiryModal === false){
            closeModalAndReset()
        }
    },[showValidateExpiryModal])

	const closeModalAndReset = () => {
		setIsModalOpen(false);
		timeLeft.current = 15 * 60; // Reset to 15 minutes
        //timeLeft.current = 30; // Reset to 30 second for testing purposes
		timerActive.current = false; // Stop the timer
		handleOnCloseModal();
	};

    return (
            <Dialog
                disableBackdropClick
                open={isModalOpen}
                classes={{ paper: styles.dialogPaper }}
            >
                <Grid container>
                    <DialogTitle>
                        <Typography className={styles.countHeader}>
                            Expired Token
                        </Typography>
                    </DialogTitle>
                    <IconButton
                        aria-label="close"
                        className={styles.closeButton}
                        onClick={closeModalAndReset}
                    >
                        <CloseIcon />
                    </IconButton>
                </Grid>
                <Divider />
                <Grid container>
                    <Typography className={styles.countBody}>
                        <div>You were idle for some time. Please validate the payment details again to proceed.</div>
                    </Typography>
                </Grid>

                <Divider />
                <DialogActions>
                    <center>
                        <Button
                            variant="outlined"
                            className={styles.buttonLogin}
                            label="close"
                            onClick={closeModalAndReset}
                        >
                            Close
                        </Button>
                    </center>
                </DialogActions>
            </Dialog>
        )
}

export default ExpiredValidateModal