import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/core/styles";
import App from "./components/App/App";
import theme from "./theme";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import createStore from "./redux/createStore";
import { callback } from "./utils/callback";
import Login from "./components/Login/Login";
import {
	checkIfHasUserSession,
	checkIfHasTpaSession,
	TokenUtil,
	TerminalUtil,
	UserInfoUtil,
	ReminderUtil,
} from "./utils/checkSession";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { createBrowserHistory } from "history";

import MomentUtils from "@date-io/moment";
import { setupInterceptors } from "./helpers/ApiClient";
import {
	setSchedule,
	toggleModal,
	toggleUnlockReload,
	toggleUserLoggingOut,
	toggleShowValidateExpiryModal,
	disableCommit,
	showEndSession,
	showReminderModal,
} from "./redux/modules/timeout";
import { showInvalidIpModal } from "./redux/modules/userInfo";
import { showDialog } from "./redux/modules/dialog";
import {
	showSessionExpiry,
	showValidateExpiry,
	refreshTokenBeforeExpiry,
} from "./utils/sessionTimeout";
import { getSessionStorage } from "./utils/storage";

const root = document.getElementById("root");
const token = TokenUtil.get();
const terminal = TerminalUtil.get(null);
const urlParams = new URLSearchParams(window.location.search);
const code = urlParams.get("code");
const state = urlParams.get("state");
const userInfo = UserInfoUtil.get(null);

import { ApolloProvider } from "@apollo/client";
import apolloClient from "./helpers/ApolloClient";
import LoginErrorRedirect from "./components/LoginErrorRedirect/LoginErrorRedirect";
import LoadingBar from "./components/BayadId/Loading/LoadingBar";
import { setDisabledAccess } from "./redux/modules/sessionAccess";

var store;

ReactDOM.render(
	<>
		<CssBaseline />
		<LoadingBar
			loading={true}
			styleProps={{
				justifyContent: "center",
				flexFlow: "row",
				alignItems: "center",
				background: "white",
			}}
			thickness={3}
			size={100}
		/>
	</>,
	root
);

if (token && terminal) {
	setupInterceptors();
	checkIfHasTpaSession(
		terminal.id,
		userInfo,
		(showViewReceiptModal, data) => {
			const initialState = {
				userInfo: {
					...userInfo,
					terminal,
				},
				transaction: {
					showViewReceiptModal: showViewReceiptModal,
					transactionList: data.transactionList,
					checkReceiptsList: data.checkReceiptsList,
				},
			};
			store = createStore(initialState);
			const history = createBrowserHistory();

			window.initializeShowSessionExpiry = (saved_time = {}) =>
				showSessionExpiry(
					() => store.dispatch(toggleModal()),
					() => store.dispatch(toggleUnlockReload()),
					() => store.dispatch(toggleUserLoggingOut()),
					saved_time
				);

			window.initializeRefreshTokenExpiry = () =>
				refreshTokenBeforeExpiry(
					() => store.dispatch(showEndSession()),
					() => store.dispatch(showInvalidIpModal()),
					(hasTime) => store.dispatch(showReminderModal(hasTime)),
					(payload) => store.dispatch(setSchedule(payload)),
					history
				);

			window.showAccessDeniedModal = () => store.dispatch(showDialog());
			window.setDisabledAccess = (obj) =>
				store.dispatch(setDisabledAccess(obj));

			window.initializeShowSessionExpiry();
			window.initializeRefreshTokenExpiry();

			setupInterceptors();

			ReactDOM.render(
				<Provider store={store}>
					<MuiPickersUtilsProvider utils={MomentUtils}>
						<ThemeProvider theme={theme}>
							<ApolloProvider client={apolloClient}>
								<CssBaseline />
								<App history={history} />
							</ApolloProvider>
						</ThemeProvider>
					</MuiPickersUtilsProvider>
				</Provider>,
				root
			);
		}
	);
} else if (code && state) {
	if (
		!(state && state.length > 0 && state === getSessionStorage("state")) ||
		!getSessionStorage("codeVerifier")
	) {
		ReactDOM.render(<LoginErrorRedirect />, root);
	} else {
		console.log("callback mode");
		callback(code).then((res) => {
			if (res === "CALLBACK ERROR")
				ReactDOM.render(<LoginErrorRedirect />, root);
		});
	}
} else {
	ReminderUtil.set(false);
	setupInterceptors();
	store = createStore({});
	checkIfHasUserSession(userInfo, (info) => {
		ReactDOM.render(
			<Provider store={store}>
				<MuiPickersUtilsProvider utils={MomentUtils}>
					<ThemeProvider theme={theme}>
						<ApolloProvider client={apolloClient}>
							<Login userInfo={info} />
						</ApolloProvider>
					</ThemeProvider>
				</MuiPickersUtilsProvider>
			</Provider>,
			root
		);
	});
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

export default store;
