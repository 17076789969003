import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setInitialLoad } from "../../redux/modules/latency";
import { ReactComponent as ConnectivityIcon } from "../../assets/icons/network/sm-network-dynamic-color.svg";
import { ReactComponent as OfflineIcon } from "../../assets/icons/network/sm-offline.svg";
import { ReactComponent as SlowIcon } from "../../assets/icons/network/sm-slow.svg";
import { ReactComponent as ConnectivityIconLoading } from "../../assets/icons/network/sm-network-loading.svg";
import styles from "./ConnectivityStatus.module.css";
import { Box, Typography } from "@mui/material";

const COLORS = {
	ORANGE: "#FB7823",
	YELLOW: "#FFD700",
	GREEN: "#05A405",
	GRAY: "#4A4A4A",
	BLUE: "#0076BF",
};

const ConnectivityStatus = () => {
	const dispatch = useDispatch();
	const latency = useSelector((state) => state.latency?.latency ?? "N/A");
	const { status: latencyStatus } = useSelector((state) => state.latency);
	const isNetworkOnline = useSelector((state) => state.latency.networkOnline);
	// Initialize status to 'loading' until the first check is completed
	const initialLoad = useSelector((state) => state.latency.initialLoad);
	// Determine color based on latency
	const getColorByLatency = (latency) => {
		if (latencyStatus === "loading" || initialLoad) return COLORS.BLUE;
		if (latency === "N/A") return COLORS.GRAY;
		if (latency <= 200) return COLORS.GREEN;
		if (latency <= 500) return COLORS.YELLOW;

		return COLORS.ORANGE;
	};

	// Get icon based on latency
	const getIconByLatency = () => {
		if (initialLoad || latencyStatus === "loading") {
			return <ConnectivityIconLoading />;
		}
		if (latency === "N/A") {
			return <OfflineIcon />;
		}
		if (latency > 500) {
			return <SlowIcon />;
		}
		return <ConnectivityIcon />;
	};

	// Get status text based on latency
	const getStatusByLatency = (latency) => {
		if (initialLoad) return "Initializing..";
		if (isNetworkOnline && latency === "N/A" && latencyStatus !== "loading")
			return "UNKNOWN";
		if (latency === "N/A") return "OFFLINE";
		if (latency <= 200) return "FAST";
		if (latency <= 500) return "GOOD";
		return "SLOW";
	};

	const formatLatency = (latency) => {
		if (latency >= 1000) {
			return `${(latency / 1000).toFixed(1)}K`;
		}
		if (latency == "N/A") {
			return "Unknown";
		}

		return latency; // Returns the original value if less than 1000
	};

	// Update the initial loading state based on the first effective change in latency or status
	useEffect(() => {
		if (latencyStatus !== "idle" || latency !== "N/A") {
			dispatch(setInitialLoad(false));
		}
	}, [latency, latencyStatus]);

	const IconComponent = getIconByLatency();
	const iconColor = getColorByLatency(latency);
	const LatencyStatus = getStatusByLatency(latency);

	return (
		<>
			<Box sx={{ display: "flex", gap: "10px " }}>
				<Box
					sx={{
						position: "relative",
						borderRadius: "100%",
						height: "27px",
						width: "27px",
						bgcolor: "white",
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						color: iconColor,
					}}
				>
					<>{IconComponent}</>
				</Box>

				{/* Container for text */}
				<div
					style={{
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						justifyItems: "center",
						gap: "12px",
					}}
				>
					<p className={styles.fontStyle}>{LatencyStatus}</p>

					<p className={styles.fontStyle}>
						{formatLatency(latency)} ms
					</p>
				</div>
			</Box>
		</>
	);
};

export default ConnectivityStatus;
