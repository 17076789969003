import { Component } from "react";
import { Card, CardContent, TextField } from "@material-ui/core";
import NumberFormat from "react-number-format";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import logo from "../../assets/images/changefunds/ic-changefund@2x.png";
import styles from "./ChangeFunds.module.css";
import cx from "classnames";
import ApiClient from "../../helpers/ApiClient";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { setSessionStorage } from "../../utils/storage";

class ChangeFunds extends Component {
	constructor(props) {
		super(props);
		this.state = {
			openDialog: true,
			hasAmount: false,
			amount: "0.0",
			openAlert: false,
			isSubmitting: false,
		};
		this.createSession = this.createSession.bind(this);
		this.createWithoutSession = this.createWithoutSession.bind(this);
		this.openAlert = this.openAlert.bind(this);
		this.handleChange = this.handleChange.bind(this);
	}

	createSession() {
		this.setState({ isSubmitting: true }); 
		let body = {
			start_amount: this.state.amount,
		};
		ApiClient.post(`/v2/terminals/change-fund`, JSON.stringify(body))
			.then(() => {
				setSessionStorage("withSession", 1);
				// let path = `/pay-bills`;
				window.location.reload();
				// this.props.history.push(path);
			})
			.catch(() => {
				this.openAlert();
				this.setState({ isSubmitting: false });
			});
	}

	createWithoutSession() {
		ApiClient.post(`/v2/terminals/no-session`)
			.then(() => {
				setSessionStorage("withSession", 0);
				let path = `/cash-management`;
				this.props.history.push(path);
			})
			.catch(() => this.openAlert());
	}

	openAlert() {
		this.setState({ openAlert: !this.state.openAlert });
	}

	handleChange(e) {
		let amount = e.target.value;
		if (amount && amount !== ".") {
			this.setState({ amount: amount, hasAmount: true });
		} else {
			this.setState({ amount: "0.0", hasAmount: false });
		}
		setSessionStorage("changeFund", amount);
	}

	render() {
		const theme = createMuiTheme({
			palette: {
				primary: {
					main: "#0176C0",
				},
			},
		});

		return (
			<div style={{ height: "100%" }}>
				<Grid
					container
					direction="row"
					alignItems="center"
					justify="center"
					className={styles.gridContainer}
				>
					<Grid item>
						<Card
							variant="outlined"
							className={styles.changeFundCard}
						>
							<CardContent>
								<Grid
									container
									spacing={2}
									direction="column"
									alignItems="center"
									justify="center"
								>
									<Grid item>
										<img
											src={logo}
											className={styles.changeFundLogo}
											alt="logo"
										/>
									</Grid>
									<Grid item>
										<Typography
											gutterBottom
											className={styles.changeFundTitle}
										>
											Enter Change Fund
										</Typography>
									</Grid>
									<Grid
										item
										className={styles.buttonGridItem}
									>
										<ThemeProvider theme={theme}>
											<TextField
												label="Amount"
												size="small"
												variant="outlined"
												color="primary"
												className={styles.amountField}
												InputProps={{
													inputComponent:
														NumberFormatCustom,
												}}
												onChange={this.handleChange}
											/>
										</ThemeProvider>
									</Grid>
									<Grid
										item
										className={styles.buttonGridItem}
									>
										<Button
											className={cx(
												styles.withSession,
												styles.sessionButton
											)}
											disabled={!this.state.hasAmount || this.state.isSubmitting}
											onClick={() => this.createSession()}
										>
											Start Session
											{this.state.isSubmitting && (<CircularProgress size={15} style={{color: "#CED4DA", marginLeft: 5}} />)}
										</Button>
									</Grid>
									<Grid
										item
										className={styles.buttonGridItem}
									>
										<Button
											className={cx(
												styles.withoutSession,
												styles.sessionButton
											)}
											onClick={() =>
												this.createWithoutSession()
											}
										>
											Start Without Session
										</Button>
									</Grid>
								</Grid>
							</CardContent>
						</Card>
					</Grid>
				</Grid>
			</div>
		);
	}
}

function NumberFormatCustom(props) {
	const { inputRef, onChange, ...other } = props;
	return (
		<NumberFormat
			{...other}
			getInputRef={inputRef}
			onValueChange={(values) => {
				onChange({
					target: {
						name: props.name,
						value: values.value,
					},
				});
			}}
			allowNegative={false}
			decimalScale={4}
		/>
	);
}

export default connect((state) => ({
	terminalId: state.userInfo.terminal.id,
}))(withRouter(ChangeFunds));
