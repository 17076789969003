import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../helpers/ApiClient";

export const measureLatency = createAsyncThunk(
	"latency/measureLatency",
	async (_, { rejectWithValue }) => {
		try {
			const start = window.performance.now();
			await axios.get("/v3/get-server-time");
			const end = window.performance.now();

			const latency = Math.round(end - start);

			return latency;
		} catch (error) {
			return rejectWithValue("Failed to measure latency");
		}
	}
);

const latencySlice = createSlice({
	name: "latency",
	initialState: {
		latency: null,
		status: "idle",
		error: null,
		networkOnline: true,
		initialLoad: true,
	},
	reducers: {
		resetLatency: (state) => {
			state.latency = null;
		},
		setNetworkOnline: (state, action) => {
			state.networkOnline = action.payload;
		},
		setInitialLoad: (state, action) => {
			state.initialLoad = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(measureLatency.pending, (state) => {
				state.status = "loading";
			})
			.addCase(measureLatency.fulfilled, (state, action) => {
				state.latency = action.payload;
				state.status = "succeeded";
			})
			.addCase(measureLatency.rejected, (state, action) => {
				state.status = "failed";
				state.error = action.payload;
				state.latency = null;
			});
	},
});

export const { resetLatency, setNetworkOnline, setInitialLoad } =
	latencySlice.actions;
export default latencySlice.reducer;
