import React, { useEffect } from "react";
import {
	Dialog,
	Box,
	Divider,
	DialogActions,
	Button,
	Typography,
	DialogTitle,
	IconButton,
    DialogContent,
    DialogContentText,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import styles from "./DuplicateErrorModal.module.css";
import { useSelector } from "react-redux";
import moment from "moment";

const DuplicateErrorModal = ({onCloseBtn, onContinueBtn, modalOpen = false}) => {
    const [open, setOpen] = React.useState(modalOpen);
    const { duplicateTransactionList } = useSelector((store) => store.transaction);

    const closeBtn = () => {
		onCloseBtn();
        setOpen(false)
	};

	const continueBtn = () => {
		onContinueBtn();
        setOpen(false)
	};
	return (
		<Dialog
            open={open}
            onClose={closeBtn}
            disableBackdropClick
            maxWidth="xs"
            classes={{ paper: styles.dialogPaper }}
        >
            <DialogTitle>
                <Typography className={styles.title}>
                    Duplicate Transaction
                </Typography>
                <IconButton aria-label="close" className={styles.buttonCloseIcon} onClick={closeBtn}>
                    <CloseIcon fontSize="large"/>
                </IconButton>
            </DialogTitle>
            <Divider />
            <DialogContent>
                <DialogContentText>
                    <Typography className={styles.body}>
                        {duplicateTransactionList[0].newTransaction === true ? 
                            'You just processed a transaction with the same details.' : 
                            'You just recently processed a transaction with the same details:'}
                            <br/>
                    </Typography>
                    <br/>
                    {duplicateTransactionList.map((data,i)=>{
                        return (
                            <Typography className={styles.body} key={i}>
                                Date & Time:&nbsp;&nbsp;<b>{moment(data.timestamp).format('MMMM DD, YYYY / hh:mm A')}</b>
                                <br/>
                                Biller:&nbsp;&nbsp;<b>{data.billerName}</b>
                                <br/>
                                Reference No.:&nbsp;&nbsp;<b>{data.accountNo}</b>
                                <br/>
                                Amount:&nbsp;&nbsp;<b>{data.amount}</b>
                                <br/>
                                <br/>
                            </Typography>
                        )
                    })}
                    {duplicateTransactionList[0].newTransaction === true ? '':
                    <>
                        <Typography className={styles.body}>
                            Are you sure you want to proceed? <br/>
                        </Typography>
                        </>
                    }
                </DialogContentText>
            </DialogContent>
            <Divider />
            <DialogActions>
            {duplicateTransactionList[0].newTransaction === true ? 
                <Button onClick={closeBtn} variant="outlined" className={styles.buttonClose}>
                    Close
                </Button>
            :
            <> 
                <Button onClick={closeBtn} variant="outlined" className={styles.buttonClose}>
                    Cancel
                </Button>
                <Button onClick={continueBtn} variant="outlined" className={styles.buttonContinue}>
                    Yes, Continue
                </Button>
            </>
            }
            </DialogActions>
        </Dialog>
	);
};

export default DuplicateErrorModal;
